import {Component, EventEmitter, Output} from '@angular/core'
import {environment} from '../../../environments/environment'
import {ConfigService} from '../../services/config.service'
import {LoanService} from '../../services/loan.service'

@Component({
  selector: 'spb-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  @Output() application = new EventEmitter()

  protected readonly canUseTestingTools = !environment.production

  constructor(
    public configService: ConfigService,
    public loanService: LoanService
  ) {
  }

}
