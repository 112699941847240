{
  "name": "ll-admin-fe",
  "version": "1.0.1-12-admin-updates-fe-part-2.1",
  "scripts": {
    "ng": "ng",
    "install": "cp ./package.json ./src/assets",
    "start": "ng serve --ssl --host localhost.sparbanken.me",
    "build": "./scripts/version.sh && ng build",
    "test": "ng test --watch=false --code-coverage",
    "lint": "ng lint",
    "style-lint": "stylelint \"src/**/*.scss\" --fix"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.0.8",
    "@angular/cdk": "^17.0.4",
    "@angular/common": "^17.0.8",
    "@angular/compiler": "^17.0.8",
    "@angular/core": "^17.0.8",
    "@angular/forms": "^17.0.8",
    "@angular/material": "^17.0.4",
    "@angular/platform-browser": "^17.0.8",
    "@angular/platform-browser-dynamic": "^17.0.8",
    "@angular/router": "^17.0.8",
    "rxjs": "~7.8.1",
    "tslib": "^2.6.2",
    "zone.js": "~0.14.2"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.0.8",
    "@angular-eslint/builder": "17.1.1",
    "@angular-eslint/eslint-plugin": "17.1.1",
    "@angular-eslint/eslint-plugin-template": "17.1.1",
    "@angular-eslint/schematics": "17.1.1",
    "@angular-eslint/template-parser": "17.1.1",
    "@angular/cli": "^17.0.8",
    "@angular/compiler-cli": "^17.0.8",
    "@angular/language-service": "^17.0.8",
    "@sparbanken-syd/loan-backend": "next",
    "@sparbanken-syd/sparbanken-syd-bankid": "^17.0.0",
    "@sparbanken-syd/sparbanken-syd-qr": "^17.0.1",
    "@sparbanken-syd/sparbanken-syd-theme": "^17.0.5",
    "@sparbanken-syd/user-documents-backend": "next",
    "@types/jasmine": "~5.1.4",
    "@types/jasminewd2": "~2.0.13",
    "@types/node": "18",
    "@typescript-eslint/eslint-plugin": "^6.17.0",
    "@typescript-eslint/parser": "^6.17.0",
    "eslint": "^8.56.0",
    "jasmine-core": "~5.1.1",
    "jasmine-spec-reporter": "~7.0.0",
    "karma": "~6.4.2",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "postcss": "^8.4.32",
    "postcss-scss": "^4.0.9",
    "stylelint": "^16.2.0",
    "stylelint-config-standard-scss": "^13.0.0",
    "stylelint-scss": "^6.1.0",
    "ts-node": "~10.9.2",
    "typescript": "~5.2.2"
  }
}
