<div class="header">
  <spb-logo title="Lånelöfte Admin"></spb-logo>
  <div [style.flex-grow]="1"></div>

  @if (configService.logInState$ | async; as user) {
    <div class="menu-holder">
      <span class="username">{{ user.name }}</span>

      <button [matMenuTriggerFor]="menu" mat-button>
        <mat-icon>menu</mat-icon>

        @if (canUseTestingTools) {
          <div class="developer-icon-holder">
            <mat-icon class="material-icons-outlined developer-icon">handyman</mat-icon>
          </div>
        }
      </button>
    </div>
  }
</div>
<mat-menu #menu="matMenu" class="menu">
  @if (canUseTestingTools) {
    <div mat-menu-item disabled="true" class="menu-title-container">
      <p class="menu-title">Testing tools menu</p>
      <p class="menu-title-info">Only available in testing environment</p>
    </div>
  }

  <button (click)="configService.logout()" mat-menu-item>Logga ut</button>

  <button (click)="loanService.isMockUcActive$.set(!loanService.isMockUcActive$())" mat-menu-item>
    @if (loanService.isMockUcActive$()) {
      <mat-icon class="active">{{ 'check_box' }}</mat-icon>
    } @else {
      <mat-icon>{{ 'check_box_outline_blank' }}</mat-icon>
    }
    <span>Mock UC</span>
  </button>
</mat-menu>